import React, { Fragment, Children, useState } from 'react';
import { PropTypes } from 'prop-types';

import Modal from './Modal';

function WithModal({ modal, children, zone, title }) {
  const [isOpen, onChange] = useState(false);
  const onOpen = () => onChange(true);
  const onClose = () => onChange(false);
  return (
    <Fragment>
      {Children.only(children({ isOpen, onOpen, onClose, zone, title }))}
      <Modal isOpen={isOpen} onClose={onClose} zone={zone} title={title}>
        {modal}
      </Modal>
    </Fragment>
  );
}

WithModal.propTypes = {
  modal: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

export default WithModal;
