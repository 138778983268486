import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Row, Col, media } from 'styled-bootstrap-grid';

import Icon from '../basic/Icon';
import { color } from '../basic/shared/styles';
import content from '../content';
import { Type1, Type2, Type3 } from '../basic/typography';
import MenuLogo from '../images/logocolor';

const Frame = styled.div`
  padding: 10px 10px 50px 10px;
  ${media.xs`
    padding: 10px 10px 50px 10px;
  `}
  ${media.sm`
    padding: 50px 50px 50px 50px;
  `}
  ${media.md`
    padding: 50px 50px 50px 50px;
  `}
`;

const LinkItem = styled(Link)`
  text-decoration: none;
  color: ${color.secondary};
`;

const SocialLink = styled.a`
  text-decoration: none;
  padding: 10px;
  color: ${color.secondary};
`;

const LinkItemA = styled.a`
  text-decoration: none;
  color: ${color.secondary};
`;

const SocialItems = styled.div`
margin-top: 30px;
`;

const Contacts = styled(Type1)`
`;
const Address = styled(Type3)`
text-align: center;
`;

const Background = styled.div`
  max-width: 950px;
  margin: 0 auto;
`;

const ColItem = styled(Col)`
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
`;

const Logo = styled(MenuLogo)`
  height: 60px;
`;

const Content = () => (
  <Background id='contact'>
    <Frame >
      <Row>
        <ColItem xm={12} sm={4} md={4}>
          <Contacts color='secondary'>{content.contact.email}</Contacts>
        </ColItem>
        <ColItem xs={12} sm={4} md={4}>
          <Logo />
          <SocialItems>
            {content.social.map((item,i)=>(
              <SocialLink key={i} href={item.url}>
                <Icon icon={item.icon} size={25} color={color.secondary}/>
              </SocialLink>
            ))}
          </SocialItems>
        </ColItem>
        <ColItem xs={12} sm={4} md={4}>
          <Contacts color='secondary'>{content.contact.phone}</Contacts>
        </ColItem>
      </Row>
      <Address color='secondary'>© {new Date().getFullYear()} MRM Tech (Pty) Ltd, 15 Northcliff Office Park, 203 Beyers Naudé Drive, Johannesburg, South Africa</Address>
    </Frame>
  </Background>
);

// <div>
//   <LinkItem to='/technology'>Our Technology Explained</LinkItem>
// </div>
// <div>
//   <LinkItem to='/partners'>Partner With Us</LinkItem>
// </div>
// <div>
//   <LinkItem to='/careers'>Careers</LinkItem>
// </div>

export default Content;
