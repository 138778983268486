import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { color } from '../basic/shared/styles';
import SectionTriangle from '../components/SectionTriangle';
import content from '../content';
import { Heading4 } from '../basic/typography';

const DividerWrapper = styled.div`
  width: 100%;
`;
const Content = styled(Heading4)`
  padding: 0px 5px 10px 5px;
  ${media.xs`
  padding: 0px 5px 10px 5px;
  `}
  ${media.sm`
  padding: 0px 10px 10px 10px;
  `}
  ${media.md`
  padding: 0px 15px 10px 15px;
  `}
  ${media.lg`
  padding: 0px 30px 10px 20px;
  `}
  ${media.xl`
  padding: 0px 50px 10px 20px;
  `}
`;
const Title = styled(Heading4)`
  padding: 10px 5px 50px 5px;
  ${media.xs`
  padding: 10px 5px 50px 5px;
  `}
  ${media.sm`
  padding: 10px 10px 50px 10px;
  `}
  ${media.md`
  padding: 10px 15px 50px 15px;
  `}
  ${media.lg`
  padding: 10px 30px 50px 20px;
  `}
  ${media.xl`
  padding: 10px 50px 50px 20px;
  `}
`;

const WrapContent = styled.div`
  margin: auto 0;
  height: ${props => props.height}px;
  position: absolute;
  max-width: 1000px;
  left: 30%;
  padding-top: 50px;
`;

const DividerTitle = ({title, content, height = 400}) => (
  <DividerWrapper>
    <SectionTriangle split={20} height={height}/>
    <WrapContent height={height}>
      {content.map((par,i)=>(
      <Content key={i} color="secondary" weight="bold">
          {par}
      </Content>
      ))}
      <Title color="appInverse" weight="black">
        {title}
      </Title>
    </WrapContent>
  </DividerWrapper>
);


export default DividerTitle;
