import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { color } from '../basic/shared/styles';

const TriangleWrapper = styled.div`
  width: 100vw;
  height: 25px;
`;

const BoxST = styled.div`
  width: 100vw;
  margin-top: -6px;
  height: ${props => props.height}px;
  ${media.xs`
  height: ${props => props.height}px;
  `}
  ${media.sm`
  height: ${props => props.height*(27/40)}px;
  `}
  ${media.md`
  height: ${props => props.height*(28/40)}px;
  `}
  ${media.lg`
  height: ${props => props.height*(3/4)}px;
  `}
  ${media.xl`
  height: ${props => props.height*(35/40)}px;
  `}
`;

const Box = ({split, height}) => (
  <BoxST height={height}>
    <svg width="100%" height="100%" preserveAspectRatio="none" viewBox="0,0,100,100">
      <polygon id="tri" points={`0 0, ${split} 0, ${split} 100, 0 100`} fill={color.forth}/>
      <polygon id="tri" points={`${split} 0, 100 0, 100 100, ${split} 100`} fill={color.forthLight}/>
    </svg>
  </BoxST>
);

const Triangle = ({split}) => (
  <svg width="100%" height="100%" preserveAspectRatio="none" viewBox="0,0,100,100">
    <polygon id="tri" points={`0 0,0 100,${split} 100,${split} 80`} fill={color.forth}/>
    <polygon id="tri2" points={`${split} 80,100 0,100 100,${split} 100`} fill={color.forthLight}/>
  </svg>
);

const TriangleB = ({split}) => (
  <svg width="100%" height="100%" preserveAspectRatio="none" viewBox="0,0,100,100">
    <polygon id="tri" points={`0 0,${split} 0,${split} 100,0 0`} fill={color.forth}/>
    <polygon id="tri2" points={`${split} 0,100 0,${split} 100,${split} 0`} fill={color.forthLight}/>
  </svg>
);



const SectionTriangle = ({split = 30, height = 400}) => (
  <TriangleWrapper>
    <Triangle split={split}/>
    <Box split={split} height={height}/>
    <TriangleB split={split}/>
  </TriangleWrapper>
);


export default SectionTriangle;
