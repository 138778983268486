/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { typography } from './shared/styles';
import { color } from './shared/styles';


export const Heading1 = styled.h1`
  letter-spacing: 0.1em;
  color: ${props => color[props.color]};
  font-weight: ${props => props.weight ? typography.weight[props.weight] : typography.weight.regular};
  font-size: ${typography.size.l2}px;
  ${media.xs`
    font-size: ${typography.size.l2}px;
  `}
  ${media.sm`
    font-size: ${typography.size.l2}px;
  `}
  ${media.md`
    font-size: ${typography.size.l2}px;
  `}
  ${media.lg`
    font-size: ${typography.size.l3}px;
  `}
  ${media.xl`
    font-size: ${typography.size.l4}px;
  `}
`;
export const Heading2 = styled.h2`
  letter-spacing: 0.05em;
  color: ${props => color[props.color]};
  font-weight: ${props => props.weight ? typography.weight[props.weight] : typography.weight.regular};
  font-size: ${typography.size.l1}px;
  ${media.xs`
    font-size: ${typography.size.l1}px;
  `}
  ${media.sm`
    font-size: ${typography.size.l1}px;
  `}
  ${media.md`
    font-size: ${typography.size.l1}px;
  `}
  ${media.lg`
    font-size: ${typography.size.l2}px;
  `}
  ${media.xl`
    font-size: ${typography.size.l3}px;
  `}
`;
export const Heading3 = styled.h3`
  letter-spacing: 0.05em;
  color: ${props => color[props.color]};
  font-weight: ${props => props.weight ? typography.weight[props.weight] : typography.weight.regular};
  font-size: ${typography.size.m2}px;
  ${media.xs`
    font-size: ${typography.size.m2}px;
  `}
  ${media.sm`
    font-size: ${typography.size.m2}px;
  `}
  ${media.md`
    font-size: ${typography.size.m3}px;
  `}
  ${media.lg`
    font-size: ${typography.size.l1}px;
  `}
  ${media.xl`
    font-size: ${typography.size.l2}px;
  `}
`;
export const Heading4 = styled.h4`
  letter-spacing: 0.05em;
  color: ${props => color[props.color]};
  font-weight: ${props => props.weight ? typography.weight[props.weight] : typography.weight.regular};
  font-size: ${typography.size.m1}px;
  ${media.xs`
    font-size: ${typography.size.m1}px;
  `}
  ${media.sm`
    font-size: ${typography.size.m1}px;
  `}
  ${media.md`
    font-size: ${typography.size.m2}px;
  `}
  ${media.lg`
    font-size: ${typography.size.m3}px;
  `}
  ${media.xl`
    font-size: ${typography.size.l1}px;
  `}
`;
export const Heading5 = styled.h5`
  letter-spacing: 0.05em;
  color: ${props => color[props.color]};
  font-weight: ${props => props.weight ? typography.weight[props.weight] : typography.weight.regular};
  font-size: ${typography.size.s4}px;
  ${media.xs`
    font-size: ${typography.size.s4}px;
  `}
  ${media.sm`
    font-size: ${typography.size.s4}px;
  `}
  ${media.md`
    font-size: ${typography.size.m1}px;
  `}
  ${media.lg`
    font-size: ${typography.size.m2}px;
  `}
  ${media.xl`
    font-size: ${typography.size.m3}px;
  `}
`;
export const Heading6 = styled.h6`
  letter-spacing: 0.05em;
  color: ${props => color[props.color]};
  font-weight: ${props => props.weight ? typography.weight[props.weight] : typography.weight.regular};
  font-size: ${typography.size.s4}px;
  ${media.xs`
    font-size: ${typography.size.s4}px;
  `}
  ${media.sm`
    font-size: ${typography.size.s4}px;
  `}
  ${media.md`
    font-size: ${typography.size.s4}px;
  `}
  ${media.lg`
    font-size: ${typography.size.m1}px;
  `}
  ${media.xl`
    font-size: ${typography.size.m2}px;
  `}
`;

export const Type1 = styled.p`
  color: ${props => color[props.color]};
  font-weight: ${props => props.weight ? typography.weight[props.weight] : typography.weight.regular};
  font-size: ${typography.size.s4}px;
  letter-spacing: 0.05em;
`;
export const Span1 = styled.span`
  color: ${props => color[props.color]};
  font-weight: ${props => props.weight ? typography.weight[props.weight] : typography.weight.regular};
  letter-spacing: 0.05em;
  font-size: ${typography.size.s4}px;
`;
export const Type2 = styled.p`
  color: ${props => color[props.color]};
  font-weight: ${props => props.weight ? typography.weight[props.weight] : typography.weight.regular};
  font-size: ${typography.size.s3}px;
  letter-spacing: 0.05em;
`;
export const Type3 = styled.p`
  color: ${props => color[props.color]};
  font-weight: ${props => props.weight ? typography.weight[props.weight] : typography.weight.regular};
  font-size: ${typography.size.s2}px;
  letter-spacing: 0.05em;
`;