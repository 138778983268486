import React from 'react';
import axios from 'axios';
import { withCookies } from 'react-cookie';


export const makeId = () => {
  let text = "";
  let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 30; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
};

export const upsetMarketing = (data) => {
  axios({
    method: 'post',
    url: 'https://api.mrmupload.com/marketing',
    //url: 'http://127.0.0.1:5000/email',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'NPkUJDM0Yw4iF5MbZiC0v84ZX6euMkrh8joOBCo1'
    },
    data: data
  }).then(res => {
    //console.log(res);
  }).catch(err => {
    console.log(err);
  });
}

export const upsetEmail = (data, callback) => {
  axios({
    method: 'post',
    url: 'https://api.mrmupload.com/email',
    //url: 'http://127.0.0.1:5000/email',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'NPkUJDM0Yw4iF5MbZiC0v84ZX6euMkrh8joOBCo1'
    },
    data: data
  }).then(res => {
    //console.log(res);
    callback('done');
  }).catch(err => {
    callback('done');
    console.log(err);
  });
}

class CookieBoxC extends React.Component {
  constructor (props) {
    super(props);
    const { cookies } = props;
    const date = new Date()
    cookies.set('date', date, { path: '/' });
    if (!cookies.get('mrm_cookie_id')){
      cookies.set('mrm_cookie_id', makeId(), { path: '/' });
    }
    this.state = {
      mrm_cookie_id: cookies.get('mrm_cookie_id'),
      date: date,
      screenClass: props.screenClass,
      page: props.page,
      application_phase: -1
    };
    upsetMarketing(this.state);
  }

  render() {
    return '';
  }
};

export const CookieBox = withCookies(CookieBoxC);
export default withCookies(CookieBoxC);












// import { Link } from 'gatsby'
// import CookieBanner from 'react-cookie-banner';
// import { Cookies } from 'react-cookie-banner';
// import cookie from '../images/cookie.png';
//
//
// // reset cookies on first render
// !state.accepted && cookies.get('accepts-cookies') && cookies.remove('accepts-cookies')
//
// const styles = {
//   banner: {
//     fontFamily: 'Source Sans Pro',
//     height: 57,
//     background: `rgba(52, 64, 81, 0.88) url(${cookie}) 20px 50% no-repeat`,
//     backgroundSize: '30px 30px',
//     backgroundColor: '',
//     fontSize: '15px',
//     fontWeight: 600
//   },
//   button: {
//     border: '1px solid white',
//     borderRadius: 4,
//     width: 66,
//     height: 32,
//     lineHeight: '32px',
//     background: 'transparent',
//     color: 'white',
//     fontSize: '14px',
//     fontWeight: 600,
//     opacity: 1,
//     right: 20,
//     marginTop: -18
//   },
//   message: {
//     display: 'block',
//     padding: '9px 67px',
//     lineHeight: 1.3,
//     textAlign: 'left',
//     marginRight: 244,
//     color: 'white'
//   },
//   link: {
//     textDecoration: 'none',
//     fontWeight: 'bold'
//   }
// }
//
// class CookieStrip
//   toggleDismissOnScroll () {
//     setState({ dismissOnScroll: !state.dismissOnScroll })
//   }
//
//   toggleDismissOnClick () {
//     setState({ dismissOnClick: !state.dismissOnClick })
//   }
//
//   resetCookiesn () {
//     cookies.remove('accepts-cookies')
//     setState({ accepted: false })
//   }
//
//   render () {
//
//     return (
//       <CookieBanner
//         styles={styles}
//         message={'At MRM Support we use cookies to improve online user experience.'}
//         link={<Link to='/privacy'>More information.</Link>}
//         buttonMessage='Close'
//         dismissOnScroll={true}
//         dismissOnClick={false}
//         onAccept={() => setState({ accepted: true })}
//       />
//     );
//   }
// }
