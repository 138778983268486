import React from 'react';
import styled from 'styled-components';
import MenuLogo from '../images/logocolor';
import { media, Col } from 'styled-bootstrap-grid'
import { Link as GatsbyLink } from 'gatsby';

import Link from '../basic/Link';
import Button from '../basic/Button';
import WithModal from '../basic/modal/WithModal';
import CtaModal from './CtaModal';
import content from '../content';

const MenuBarSt = styled.div`
  margin-bottom: 1.45rem;
  background-color: rgba(28,39,43,0.8);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 70px;
  width: 100%;
  z-index: 9999;
  border-bottom: 1pt solid rgba(28,39,43);
  ${media.md`
    padding-left: 10px;
  `}
  ${media.lg`
    padding-left: 30px;
  `}
  ${media.xl`
    padding-left: 40px;
  `}
`;

const LogoImgSt = styled.div`
  width: 50px;
  float: left;
  margin: 10px 10px;
`;

const LogoTextSt = styled.div`
  width: 280px;
  font-weight: 600;
  padding: 23px;
  font-size: 23px;
`;

const MenuItemsST = styled.div`
  position: fixed;
  display: inline;
  right: 0px;
  top: 0px;
  margin: 15px;
  ${media.sm`
    padding-right: 0px;
  `}
  ${media.md`
    padding-right: 10px;
  `}
  ${media.lg`
    padding-right: 30px;
  `}
  ${media.xl`
    padding-right: 40px;
  `}
`;

const MenuItems = styled(Col)`
  display: inline;
  padding: 0px 5px;
`;

const Menu = () => (
  <MenuBarSt>
    <GatsbyLink
      to="/"
      style={{
        color: `black`,
        textDecoration: `none`,
      }}
    >
      <LogoImgSt>
        <MenuLogo width={'180px'} />
      </LogoImgSt>
    </GatsbyLink>
    <MenuItemsST >
      {content.menu.items.map(item=>
        <MenuItems key={item.title} hiddenSmDown={!item.mobile} >
          <Link to={`/${item.link}`} isMenu >
            {item.title}
          </Link>
        </MenuItems>
      )}
      <WithModal modal={CtaModal}>
        {({ onOpen }) => (
          <Button outline round onClick={onOpen} role="button" tabIndex="0">
            {content.menu.cta.title}
          </Button>
        )}
      </WithModal>
    </MenuItemsST>
  </MenuBarSt>
);

export default Menu;
