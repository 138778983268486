import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { GlobalStyle } from '../basic/shared/global';

const SiteBody = styled.div`
  margin: 0px auto -50px auto;
  padding-top: 0px;
  background: -webkit-gradient(linear,left top, left bottom,color-stop(7.68%, #1c272b),color-stop(46.51%, #2b3b41),color-stop(66.76%, #315562),color-stop(93.96%, #315562));
  background: linear-gradient(${props=> !props.inverse ? '180deg' : '0deg' },#1c272b 7.68%,#2b3b41 46.51%,#315562 66.76%,#315562 93.96%)
`;

const Layout = ({ children, inverse }) => (
  <SiteBody inverse={inverse}>
    <GlobalStyle />
    {children}
  </SiteBody>
);

export default Layout;
