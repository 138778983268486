import React from 'react'

const Logo = (props) => (
  <svg
    viewBox="0 0 342 90"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={1.414}
    {...props}
  >
    <path
      d="M.066 74.662L25 89.998v-30l25.001 14.996V44.998L0 14.998l.066 59.664z"
      fill="#3a8756"
    />
    <path
      d="M100 14.998l-49.999 30v29.996l24.998-14.996v30l25.004-15.004L100 14.998z"
      fill="#65c186"
    />
    <path
      d="M25 0l25.001 14.998L74.999 0 100 14.998l-49.999 30L0 14.998C8.387 10.002 16.667 4.999 25 0z"
      fill="#4cb071"
    />
    <path d="M25 89.998l25.001-15.004L25 59.998" fill="#65c186" />
    <path
      d="M74.999 89.997L50.001 74.994l24.998-14.996v29.999z"
      fill="#3a8756"
    />
    <text
      x={112.896}
      y={60.923}
      fontFamily="'Roboto-Bold','Roboto'"
      fontWeight={700}
      fontSize={48}
      fill="#e4ebed"
    >
      {'MRM '}
      <tspan x="238.17px 265.779px" y="60.923px 60.923px">
      {'Tech'}
      </tspan>
    </text>
  </svg>);

export default Logo;
