import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import content from '../content';
import EmailInput from '../components/EmailInput';
import { Heading3 } from '../basic/typography';

const Wrapper = styled.div`
  width: 100%;
`;
const CTA = styled(Heading3)`
  padding: 10px 0px;
  text-align: center;
`;

const ColorBox = styled.div`
  text-align: center;
  padding: 20px;
  margin: 0 auto;
  ${media.xs`
    padding: 20px 0px;
  `}
  ${media.sm`
    padding: 10px 0px;
  `}
`;



const EmailWrapper = styled.div`
  margin: 0 auto;
  padding: 10px 0px;
  ${media.xs`
    width: 300px;
  `}
  ${media.sm`
    width: 330px;
  `}
  ${media.md`
    width: 350px;
  `}
`;

const CtaOne = () => (
  <Wrapper>
    <ColorBox>
      <CTA color="secondary">
        {content.cta.one.title}
      </CTA>
      <EmailWrapper>
        <EmailInput buttonLabel={content.cta.one.button} zone="CtaOne" />
      </EmailWrapper>
    </ColorBox>
  </Wrapper>
);

export default CtaOne;
