module.exports = {
  meta: {
    title: "MRM Tech | Data Asset Management | South Africa",
    description: "MRM Tech - We help manage, understand and grow your data assets.",
    author: "MRM Tech (Pty) Ltd.",
    background_color: "#5AB45A",
    theme_color: "#5AB45A",
    keywords: ["data automation", "business process", "bi", "erp", "iot", "internet of things", "webforms", "web apps", "reporting", "business intelligence", "database management"],
    lang: "en",
    siteUrl: "https://www.mrmtech.co.za",
    metaImage: {
      image: "/images/open-graph.jpg",
      width: "1200px",
      height: "630px",
      alt: "MRM Tech - We help manage, understand and grow your data assets."
    },
    short_name: 'mrmtech',
    story_views: ['responsive', 'iphone5', 'iphone6', 'galaxys9', 'ipad', 'pixel']
  },
  
  menu: {
    items: [
      {
        title: "Case Studies",
        link: "studies",
        mobile: false
      },
      {
        title: "Services",
        link: "#services",
        mobile: false
      },
      {
        title: "Contact",
        link: "#contact",
        mobile: false
      }
    ],
    cta: {
      title: "Let's Talk",
      mobile: true
    }
  },
  hero: {
    titles: [
      "Is your data growing faster than your company?",
      "Are you treating all data in your company as an asset?"
    ],
    subtitle: "MRM Tech helps you understand, automate and grow your business data assets."
  },
  trust: {
    companies: ["avis", "bwe", "sony", "vox", "mrmsupport", "lexus", "cpl", "rtt", "avisfleet", "wilderness", "hb", "congo"],
    quotes: [
      {
        quote: "MRM exceeded all our expectation! They have also been efficient in improving on our requests and streamlining our processes. MRM is extremely hands on, and I would highly recommend their service.",
        name: "Naila Moosa",
        job: "Head of Retail - Sony Mobile, South Africa",
        company: "sony"
      },
      {
        quote: "I approached MRM with 2 ideas which they have turned into a workable data process that we can continue to expand and develop. They have a good understanding of our business processes and are extremely flexible in making changes as required.",
        name: "Mandy Holder-Ferrao",
        job: "Franchisee Owner - Avis Van Rental, South Africa",
        company: "avis"
      },
    ]
  },
  //Your business is has outgrown Excel, whats next?
  //The cost and complexity of a

// Journey Title
journey_title: {
  title: "Let's develop your business in the cloud.",
  liner: "Quick. Scalable. Insights.",
  subTitle: "Work with our engineers to define a solution which perfectly empowers your existing business operations."
},
journey: [
  {
    title: "Understand",
    subTitle: "Your business is unique, you need the freedom of a custom solution",
    items: [
      { icon: 'expand',
        text: "Map out business complexities",
      },
      { icon: 'edit',
        text: "Design data processes",
      },
      { icon: 'transfer',
        text: "Integrate existing environments",
      },
      { icon: 'filter',
        text: "Reducing human touch points"
      }
    ],
    btn: "Start Now"
  },
  {
    title: "Build",
    subTitle: "Fast, agile, low code development and implementation in the cloud",
    items: [
      { icon: 'lightning',
        text: "Pipeline all data sources",
      },
      { icon: 'batchaccept',
        text: "Improve data integrity",
      },
      { icon: 'merge',
        text: "Deploy controlled workflows",
      },
      { icon: 'switchalt',
        text: "Evolve with your business"
      }
    ],
    btn: "Deploy Now"
  },
  {
    title: "Grow",
    subTitle: "Committed to your success, we create long term partnerships",
    items: [
      { icon: 'useradd',
        text: "Increase employee efficiency",
      },
      { icon: 'eye',
        text: "Get business insights",
      },
      { icon: 'dashboard',
        text: "Live data report dashboards",
      },
      { icon: 'globe',
        text: "Scale in the cloud"
      }
    ],
    btn: "Enquire Now"
  },
],
caseStudies: {
  title: 'We help companies manage, understand and grow data assets.',
  subTitle: ["Businesses that evolve as new opportunities open up are more likely to thrive in the data-driven economy. You need a software partner that can efficiently adapt your data processes as your business evolves."],
  studies: [
    {
      title: "Vehicle Damage Management",
      company: "avis",
      paragraphs: [
        "A franchise business model has its advantages, but in some cases head office has limited control over how owners apply business processes. Implementing a data process management tool which reduces user input variability results in more consistent and controlled business outcomes.",
        "By applying this principle in Avis Van Rental with regard to vehicle damage processing, accurate information is now continuously provided to head office by all fleet owners across all their renting stations. This opened new opportunities to accurately compare renting stations and pick up on trend anomalies which were previously hidden in inconsistent and inaccurate data."
      ],
      file: '/casestudies/MRMTECH_Case_Damange_Management_9jF3B.pdf',
      zone: 'study_vehicle_damage_management'
    },
    {
      title: "Call Center Automation",
      company: "mrm",
      paragraphs: [
        "The success of call center operations can be tracked by three measurables: accuracy, efficiency and amiability. With the successful implementation of a fully automated data process both accuracy and efficiency spiked, as for amiability or friendliness, a little bit of sweet culture goes a long way.",
        "When processing 100s of outgoing customer research calls daily, MRM Support has to ensure the highest level of accuracy is maintained while managing the efficiency required to process large volumes of information. With an end to end data automation solution MRM Support can now track the details of every interaction, from the point where data is shared by the customer up to fully customizable real time reports."
      ],
      zone: 'study_call_center_automation'
    },
    {
      title: "In-Field Data Capturing",
      company: "sony",
      paragraphs: [
        "Managing a group of off-site employees and getting all agents to consistently file accurate reports is a daunting task. By implementing a powerful and accessible data capturing tool, and presenting business insights on live dashboards, both the day to day operations and reporting was simplified.",
        "In order to better develop a strategic marketing advantage, Sony Mobile needed to gather high volume data from all mobile operator channels and in-store insights from in-field sales agents. MRM Tech deployed a flexible data capturing solution to track agent participation, manage sales target communication, gather in-store marketing insights and pipeline data from all mobile operator channels to provide a single validated source of sales performance and stock levels in almost 1500 cellular stores."
      ],
      zone: 'study_infield_data_capturing'
    },
    {
      title: "Market Research Data Automation",
      company: "cpl",
      paragraphs: [
        "Qualitative market research delivers excellent in depth market insights, but can be time consuming and error prone when done manually. The implementation of a data process to fascilitate data capturing and reporting operations, provided quality and reliable analyses, faster.",
        "The Consumer Psychology Lab conduct extensive interviews with Lexus South Africa customers and needed a reliable system to speed up their process - from the interviews being done through to the final analyses and reporting provided for both Lexus South Africa and individual dealerships. A solution was delivered which fascilitates the interview process and post-interview categorisation, the management of interviewers and dealerships, and provides interactive reporting on customer experience trends down to dealership level."
      ],
      zone: 'study_market_research_data_automation'
    }
  ]
},
feature: {
  title: "Your business, One platform.",
  itemsLeft: [
    "Granular user access control",
    "Unlimited application users",
    "Fully managed service",
    "Integrated BI and process applications",
    "Data change logging",
    "Complex webform survey trees"
  ],
  itemsRight: [
    "Custom email notifications",
    "Mobile friendly",
    "Reactive dashboard filtering",
    "Generate documents",
    "Charts of any kind",
    "Combine data from diverse sources"
  ]
},
tech: {
  title: "Technical Title",
  subTitle: "All the bits in the right places",
  items: [
    {
      title: "Security"
    },
    {
      title: "Data Privacy"
    },
    {
      title: "Cloud Power"
    },
  ]
},
  cta: {
    one: {
      title: "Need to automate any process?",
      button: "Let's Chat"
    },
    two: {
      title: "To many spreadsheets? Stop missing out on insights.",
      button: "Contact Us"
    },
    modal: {
      title: "Yay! 🎉 Submit your email and we will be in contact soon.",
      button: "Send It"
    }
  },
  platform: {
    heading: "Our online platform built around your business",
    listLeft: [
      "Granular Access Control",
      "Unlimited Company Users",
      "Email, SMS Event Notifications",
      "Customizable Live BI Dashboards",
    ],
    listRight: [
      "Pipeline External Data",
      "Advanced Data Write-Back",
      "Evolve Business Process Flows",
      "Action Data in Custom Workflows",
    ]
  },
  social: [
    {
      url: 'https://za.linkedin.com/company/mrm-tech',
      icon: 'linkedin',
    },
    {
      url: 'https://medium.com/mrmsupport',
      icon: 'medium',
    },
    {
      url: 'https://github.com/techmrm',
      icon: 'github',
    },
  ],
  contact: {
    phone: "+27 11 476 3932",
    email: "hello@mrmtech.co.za",

  },
};

// export default content;
