import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from './shared/styles';
import icons from './shared/icons';

const Svg = styled.svg`
  display: inline-block;
  vertical-align: middle;
`;

const Path = styled.path`
  fill: ${props=>props.color || color.darkest};
`;

function Icon({ icon, color, size, ...props }) {
  return (
    <Svg viewBox="0 0 1024 1024" width={`${size}px`} height={`${size}px`} {...props}>
      <Path d={icons[icon]} color={color}/>
    </Svg>
  );
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
};

Icon.defaultProps = {
  size: 20
};

export default Icon;
