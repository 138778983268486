import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../basic/Button';
import Input from '../basic/Input';

import { upsetEmail, upsetMarketing } from './Cookies';
import { color, background, typography } from '../basic/shared/styles';


const InlineInput = styled.div`
  display: inline-block;
  margin-right: -30px;
`;

const InlineButton = styled.div`
  display: inline-block;
`;

const MailingListConfirm = styled.div`
  font-size: ${typography.size.s2}px;
  line-height: 20px;
  background: ${color.forthLight};
  color: black;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
`;

const MailingListError = styled.div`
  font-size: ${typography.size.s2}px;
  line-height: 20px;
  background: ${background.negative};
  color: black;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
  margin: 10px;
`;

const InputWrap = styled.div`
  display: flex;
`;

const useInput = (init) => {
  const [input, setInput] = useState(init);
  const onInputChange = (e) => {
    setInput(e.target.value);
  };
  return [input, onInputChange];
};
const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const NotValidEmail = () => (
  <MailingListError>
    <b>
      <span role="img" aria-label="thumbs up">
        🙄
      </span>
      {' '}
      Please type a valid email :)
    </b>
  </MailingListError>
);

const EmailInput = ({buttonLabel, defaultValue, placeholder, inputStyle, zone, onClick}) => {
  const [submit, changeSubmit] = useState('null');
  const [email, changeEmail] = useInput(defaultValue);

  if (submit === 'done') {
    return (
      <MailingListConfirm>
        <b>
          <span role="img" aria-label="thumbs up">
            👍
          </span>
          {' '}
          Thank you! We will be in contact soon.
        </b>
      </MailingListConfirm>
    );
  }
  return (
    <>
      <InputWrap>
        <InlineInput style={inputStyle}>
          <Input
            value={email}
            round
            borders
            loading={submit === 'loading'}
            placeholder={placeholder}
            appearance="primary"
            icon="email"
            onChange={changeEmail}
          />
        </InlineInput>
        <InlineButton >
          <Button onClick={()=>{
            changeSubmit('null');
            if (validateEmail(email)) {
              changeSubmit('loading');
              upsetMarketing({email: email, zone: zone, channel: 'mrmtech'});
              upsetEmail({email: email, zone: zone, channel: 'mrmtech'}, changeSubmit);
              onClick();
            } else {
              changeSubmit('error');
            }
          }}
          round
          loading={submit === 'loading'}
          >{buttonLabel}</Button>
        </InlineButton>
      </InputWrap>
      { submit === 'error' && <NotValidEmail /> }
    </>
  )
};

EmailInput.propTypes = {
  buttonLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  inputStyle: PropTypes.object,
  onClick: PropTypes.func
};

EmailInput.defaultProps = {
  buttonLabel: "Click Here",
  placeholder: 'Your email',
  inputStyle: {width: '100%'},
  onClick: ()=>{}
};

export default EmailInput;
