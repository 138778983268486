import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import SchemaOrg from './SchemaOrg';
import ogImage from '../images/open-graph.jpg';

const SEO = ({
  author,
  description,
  lang,
  meta,
  keywords,
  title,
  metaImage,
  siteUrl,
}) => (
  <>
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={title}
      script={[
      ]}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `viewport`,
          content: `width=device-width, initial-scale=1, shrink-to-fit=no`,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  property: `og:image`,
                  content: siteUrl+ogImage
                },
                {
                  property: `og:url`,
                  content: siteUrl
                },
                {
                  property: `og:type`,
                  content: `website`
                },
                {
                  property: `og:secure_url`,
                  content: siteUrl+ogImage
                },
                {
                  property: `og:image:type`,
                  content: `image/jpeg`
                },
                {
                  name: `twitter:card`,
                  content: `summary_large_image`
                },
                {
                  property: `og:image:alt`,
                  content: metaImage.alt
                },
                {
                  property: `twitter:image:alt`,
                  content: metaImage.alt
                },
              ]
            : [
                {
                  name: `twitter:card`,
                  content: `summary`
                }
              ]
        )
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
    <SchemaOrg
      isBlogPost={false}
      url={siteUrl}
      title={title}
      image={siteUrl+ogImage}
      description={description}
      canonicalUrl={siteUrl}
      author={author}
      organization={author}
      defaultTitle={title}
    />
  </>
);

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  metaImage: {image: '', width: '', height: '', alt: ''}
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired
}

export default SEO
