import React, { useState } from 'react';
import Button from '../basic/Button';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { color, background, typography } from '../basic/shared/styles';

import content from '../content';
import EmailInput from '../components/EmailInput';

const ModalWrap = styled.div`
  background: ${color.appInverse};
  color: white;
  padding: 30px;
  border-radius: 20px;
`;

const CTA = styled.div`
  margin: 0 auto;
  text-align: center;
  ${media.xs`
    font-size: ${typography.size.m1}px;
  `}
  ${media.sm`
    font-size: ${typography.size.m2}px;
  `}
  ${media.md`
    font-size: ${typography.size.m3}px;
  `}
`;

const EmailWrapper = styled.div`
  margin: 10px auto;
  text-align: center;
  max-width: 350px;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
`;

// eslint-disable-next-line react/prop-types
const CtaModal = ({ onClose, zone }) => {
  const [submit, changeSubmit] = useState(false);
  return (
    <ModalWrap>
      <CTA>
        {content.cta.modal.title}
      </CTA>
      <EmailWrapper>
        <EmailInput buttonLabel={content.cta.modal.button} zone={zone} onClick={()=>{changeSubmit(true)}}/>
      </EmailWrapper>
      {submit &&
        <ButtonWrapper>
          <Button primary round onClick={onClose} role="button" tabIndex="0">
            Continue Reading
          </Button>
        </ButtonWrapper>
      }
    </ModalWrap>
  );
};

export default CtaModal;
